import React, { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { acceptSubscription } from "../../api";
import Container from "../../components/Container";
import "./accept-subscription.css";

const queryString = require("query-string");

const AcceptSubscriptionPage = () => {
  const { email } = queryString.parse(window.location.search);
  const [submited, setSubmited] = useState(false);
  const history = useHistory();

  useEffect(() => {
    accept();
  }, []);

  useEffect(() => {
    if (submited) {
      history.push("/");
    }
  }, [submited, history]);

  const accept = async () => {
    if (email) {
      const response = await acceptSubscription(email);
      if (response.status === 200) {
        setSubmited(true);
      }
    }
  };

  return (
    <Container size="lg">
      <div className="accept-subscription-container">
        <Link to="/" className="checkmark">
          <IoIosCheckmarkCircle size={250} color="black" />
          <h3>Return to home page</h3>
        </Link>
      </div>
    </Container>
  );
};

export default AcceptSubscriptionPage;
