import React, { useContext } from 'react'
import SavedArticlesContext from '../../context/SavedArticlesContext';
import ReactGa from 'react-ga';
import { HiOutlineHeart, HiHeart } from 'react-icons/hi'
import "./like.css";

const Like = ({ article }) => {
  const { saved, setSaved } = useContext(SavedArticlesContext);

  const handleSave = (article) => {
    if (saved.find((savedArticle) => savedArticle.title === article.title)) {
      ReactGa.event({
        category: "Button",
        action: `Erased ${article.title} article from saved articles`
      })
      setSaved((saved) =>
        saved.filter((savedArticle) => savedArticle.title !== article.title)
      );
    } else {
      ReactGa.event({
        category: "Button",
        action: `Saved ${article.title} article`
      })
      setSaved((saved) =>
        saved && saved.length > 0 ? [...saved, article] : [article]
      );
    }
  };
  return (
    <div className="articles-save">
      <button
        className="articles-save-button"
        onClick={() => handleSave(article)}
      >
        {saved &&
          saved.length > 0 &&
          saved.find(
            (savedArticle) =>
              savedArticle.title === article.title
          ) ? (
          <HiHeart color="black" size={24} />
        ) : (
          <HiOutlineHeart color="black" size={24} />
        )}
      </button>
    </div>
  )
}

export default Like
