import React, { useState, useRef } from "react";
import "./menu.css";
import { Link } from "react-router-dom";
import useOutsideClick from "../../utils/useOutsideClick";
import { Transition } from "react-transition-group";

const defaultStyles = {
  transition: ".5s all",
};

const menuTransition = {
  entering: { left: 0 },
  entered: { left: 0 },
  exiting: { left: "-500px" },
  exited: { left: "-500px" },
};

const backgroundTransition = {
  entering: { zIndex: 10 },
  entered: { zIndex: 10, pointerEvents: "auto" },
  exiting: { opacity: 0 },
  exited: { opacity: 0, zIndex: -1 },
};

const Menu = () => {
  const [expanded, setExpanded] = useState();

  const ref = useRef();

  useOutsideClick(ref, () => {
    if (expanded) {
      setExpanded(false);
    }
  });

  return (
    <div>
      <Transition in={expanded} timeout={0}>
        {(state) => (
          <div
            className="menu-background"
            style={{ ...defaultStyles, ...backgroundTransition[state] }}
          ></div>
        )}
      </Transition>
      <div className="menu-container" ref={ref}>
        <div
          className={`hamburger-menu ${expanded ? "expanded" : ""}`}
          onClick={() => setExpanded(!expanded)}
        >
          <div className="line line-1"></div>
          <div className="line line-2"></div>
          <div className="line line-3"></div>
        </div>
        {expanded && <div className="hamburger-menu-background"></div>}
        <Transition in={expanded} timeout={0}>
          {(state) => (
            <div
              style={{ ...defaultStyles, ...menuTransition[state] }}
              className={`menu`}
            >
              <div className="menu-inner">
                <div className="menu-content">
                  <ul className="menu-list menu-main-list">
                    <li className="menu-list-item">
                      <Link
                        className="menu-list-link"
                        to="/music"
                        onClick={() => setExpanded(false)}
                      >
                        Music
                      </Link>
                    </li>
                    <li className="menu-list-item">
                      <Link
                        className="menu-list-link"
                        to="/movie"
                        onClick={() => setExpanded(false)}
                      >
                        Movie
                      </Link>
                    </li>
                    <li className="menu-list-item">
                      <Link
                        className="menu-list-link"
                        to="/literature"
                        onClick={() => setExpanded(false)}
                      >
                        Literature
                      </Link>
                    </li>
                    <li className="menu-list-item">
                      <Link
                        className="menu-list-link"
                        to="/photography"
                        onClick={() => setExpanded(false)}
                      >
                        Photography
                      </Link>
                    </li>
                    <li className="menu-list-item">
                      <Link
                        className="menu-list-link"
                        to="/lifestyle"
                        onClick={() => setExpanded(false)}
                      >
                        Lifestyle
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};

export default Menu;
