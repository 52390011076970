import React, { useState } from "react";
import "./contact.css";
import Container from "../../components/Container/";

const ContactPage = () => {
  const [form, setForm] = useState({});

  const handleChange = (e, name) => {
    if (e && e.target && e.target.value) {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({ name: "", email: "", subject: "", message: "" });
  };

  return (
    <Container>
      <h1 className="contact-page">CONTACT PAGE</h1>
      <hr className="divider" />
      <form className="contact-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="input-container">
          <label htmlFor="name">Name</label>
          <input
            placeholder="Name"
            type="text"
            className="contact-input "
            id="name"
            required
            value={form.name}
            onChange={(e) => handleChange(e, "name")}
          />
        </div>
        <div className="input-container">
          <label htmlFor="email">Email</label>
          <input
            required
            placeholder="Email"
            type="mail"
            className="contact-input "
            id="email"
            value={form.email}
            onChange={(e) => handleChange(e, "email")}
          />
        </div>
        <div className="input-container">
          <label htmlFor="subject">Subject</label>
          <input
            required
            placeholder="Subject"
            type="text"
            className="contact-input "
            id="subject"
            value={form.subject}
            onChange={(e) => handleChange(e, "subject")}
          />
        </div>
        <div className="input-container">
          <label htmlFor="Message">Message</label>
          <textarea
            className="contact-input "
            placeholder="Message"
            onChange={(e) => handleChange(e, "message")}
          >
            {form.message}
          </textarea>
        </div>
        <button className="primary-button">Send</button>
      </form>
    </Container>
  );
};

export default ContactPage;
