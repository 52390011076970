import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import FooterLogo from "../../assets/images/tape-rotate.png";
import MilkshakeLogo from "../../assets/images/milkshake-rotate.png";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-logos-cont">
          <img src={FooterLogo} alt="tape" className="footer-logo" />
        </div>
        <div className="social">
          <ul className="social-links">
            <li>
              <Link
                to={{ pathname: "https://www.facebook.com/tapemagazzine/" }}
                target="_blank"
              >
                <FaFacebook color="white" size={17} />
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.youtube.com/channel/UCIksKH_mxzJJVkFo5trASww",
                }}
                target="_blank"
              >
                <FaYoutube color="white" size={19} />
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "https://www.instagram.com/tape_magazine/" }}
                target="_blank"
              >
                <FaInstagram color="white" size={18} />
              </Link>
            </li>
          </ul>
        </div>
        <div className="links">
          <ul className="footer-links">
            <li className="footer-links-list-item">
              <Link className="footer-links-list-link" to="/contact">
                Contact
              </Link>
            </li>
            <li className="footer-links-list-item">
              <Link className="footer-links-list-link" to="/about">
                About
              </Link>
            </li>
            <li className="footer-links-list-item">
              <Link className="footer-links-list-link" to="/legal">
                Legal
              </Link>
            </li>
            <li className="footer-links-list-item">
              <Link className="footer-links-list-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </ul>
          <ul className="footer-logos">
            <li className="footer-logos-list-item category-link">
              <Link to="/music">Music</Link>
            </li>
            <li className="footer-logos-list-item category-link">
              <Link to="/movie">Movie</Link>
            </li>
            <li className="footer-logos-list-item category-link">
              <Link to="/literature">Literature</Link>
            </li>
            <li className="footer-logos-list-item category-link">
              <Link to="/photography">Photography</Link>
            </li>
            <li className="footer-logos-list-item category-link">
              <Link to="/lifestyle">Lifestyle</Link>
            </li>
            {/* <li className="footer-logos-list-item milkshake"> */}
            {/*   {navigator.onLine ? ( */}
            {/*     <Link to="/milkshake">Milkshake</Link> */}
            {/*   ) : ( */}
            {/*     <p>Milkshake</p> */}
            {/*   )} */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
