import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/images/tape-logo.png";
import { Link, useHistory } from "react-router-dom";
import Menu from "../Menu";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import useWindowDimensions from "../../utils/useWindowDimensions";
import LanguageSelector from "../LanguageSelector";

const Header = ({ logo = Logo, link = "/", text = "" }) => {
  const [onTop, setOnTop] = useState(true);
  const history = useHistory();
  const isHomePage = history.location.pathname === "/" ? true : false;
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY <= 0) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }
    });
  }, []);

  return (
    <div className="header-container">
      <div
        className={`header ${onTop && isHomePage ? "invert-header" : ""}`}
        style={
          onTop && isHomePage
            ? { borderBottom: "" }
            : { borderBottom: ".5px solid rgba(0,0,0,.4)" }
        }
      >
        <div className="navbar-menu">
          <Menu />
          <div className="navbar-lang">
            <LanguageSelector />
          </div>
        </div>
        <Link to={link}>
          <img src={`/logo.ico`} alt="Logo" className="logo" />
        </Link>
        <div className="navbar-milkshake">
          <div className="social">
            <ul className="social-links">
              <li>
                <Link
                  to={{ pathname: "https://www.facebook.com/tapemagazzine" }}
                  target="_blank"
                >
                  <FaFacebook color="black" size={width < 724 ? 12 : 17} />
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname:
                      "https://www.youtube.com/channel/UCIksKH_mxzJJVkFo5trASww",
                  }}
                  target="_blank"
                >
                  <FaYoutube color="black" size={width < 724 ? 13 : 18} />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "https://www.instagram.com/tape_magazine/" }}
                  target="_blank"
                >
                  <FaInstagram color="black" size={width < 724 ? 14 : 19} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
