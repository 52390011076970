import React, { useState, useEffect, useContext } from "react";
import "./home.css";
import {
  getLatestArticles,
  getRandomArticles,
} from "../../api";
import { useQuery } from "react-query";
import SavedArticlesContext from "../../context/SavedArticlesContext";

import ArticleTeaser from "../../components/ArticleTeaser";
import Container from "../../components/Container";
import HomePageFeatured from "../../components/HomePageFeatured";
import Video from "../../components/Video";
import ArticlesGrid from "../../components/ArticlesGrid";

const queryParams = require("query-string");

const HomePage = () => {
  const [articles, setArticles] = useState([]);
  const [online, setOnline] = useState(navigator.onLine);
  const [articlesNextPage, setArticlesNextPage] = useState(false);
  const [oneRandomArticle, setOneRandomArticle] = useState();

  useQuery("one-random-article", () => getRandomArticles(2), {
    onSuccess: (data) => {
      if (data && data.data) {
        setOneRandomArticle(data.data);
      }
    },
    refetchInterval: 15000,
  });

  useQuery("articles", () => getLatestArticles(articles.length > 0 ? 8 : 9), {
    onSuccess: (data) => {
      if (data && data.data) {
        if (articles) {
          if (data.data.length >= articles.length) {
            setArticles(data.data);
            if (data.links.next && data.links.next.href) {
              let urlObject = queryParams.parseUrl(data.links.next.href);
              urlObject.query["page[limit]"] =
                parseInt(urlObject.query["page[limit]"]) - 1;
              const newUrl = { href: queryParams.stringifyUrl(urlObject) };
              setArticlesNextPage(newUrl);
            }
          }
        } else {
          setArticles(data.data);
          setArticlesNextPage(data.links.next);
        }
      }
    },
  });

  const { saved } = useContext(SavedArticlesContext);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  return (
    <div className="home">
      {online ? (
        <>
          <Container size="md">
            <HomePageFeatured article={articles[0]} />
            <div className="recent-posts">
              <ArticlesGrid
                title="Latest News"
                description="from Tape"
                next={articlesNextPage}
                setNext={setArticlesNextPage}
                articles={articles}
                setArticles={setArticles}
                loading={!articles}
              />
            </div>
            <br />
            <br />
            {saved && saved.length > 0 && (
              <div className="saved-posts">
                <ArticlesGrid
                  title="Saved Articles"
                  articles={saved}
                  remove={0}
                />
              </div>
            )}
          </Container>
          <div className="video-wrapper">
            <Video />
          </div>
          {oneRandomArticle && (
            <Container size="sm">
              <ArticleTeaser
                article={oneRandomArticle && oneRandomArticle[0]}
                size="big"
              />
            </Container>
          )}
        </>
      ) : (
        <Container size="lg">
          <div
            className="offline-mode-container"
            style={{ height: "100%", textAlign: "center", paddingTop: "4rem" }}
          >
            <h1 style={{ marginTop: "0", width: "100%" }}>
              You are in offline mode.
            </h1>
            <p>Go online to see new articles.</p>
          </div>
        </Container>
      )}
    </div>
  );
};
export default HomePage;
