import React, { useState } from "react";
import "./literature.css";

import Container from "../../components/Container";
import ArticlesGrid from "../../components/ArticlesGrid";
import { useQuery } from "react-query";
import { getArticlesByCategory } from "../../api";
import ArticleTeaser from "../../components/ArticleTeaser";

const LiteraturePage = () => {
  useQuery("literature-articles", () => getArticlesByCategory("ლიტერატურა"), {
    onSuccess: (data) => {
      if (data) {
        if (data.data) {
          setArticles(data.data);
        }
        if (data.links.next) {
          setNextPage(data.links.next);
        }
      }
    },
  });
  const [articles, setArticles] = useState([]);
  const [nextPage, setNextPage] = useState(false);

  return (
    <Container size="sm">
      <ArticleTeaser size="big" article={articles[0]} />
      <Container size="sm">
        <ArticlesGrid
          title="Latest"
          articles={articles}
          setArticles={setArticles}
          next={nextPage}
          setNext={setNextPage}
          showBody
        />
      </Container>
    </Container>
  );
};

export default LiteraturePage;
