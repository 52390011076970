import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query';
import {getArticlesByCategory} from '../../api';
import ArticlesGrid from '../../components/ArticlesGrid';
import ArticleTeaser from '../../components/ArticleTeaser';
import Container from '../../components/Container';

const PunkInPrintPage = () => {
  const { data: { data, links: { next } } } = useQuery("punk-in-print-articles", () => getArticlesByCategory("Punk in print"), { placeholderData: { data: { }, links: { } } } );
  const [articles, setArticles] = useState([]);
  const [nextPage, setNextPage] = useState(false);

  useEffect(() => {
    if (data || next) {
      setArticles(data);
      setNextPage(next);
    }
  }, [data, next]);

  return (
    <Container>
      <ArticleTeaser article={articles[0]} size="big" />
      <Container size="sm">
        <ArticlesGrid articles={articles} setArticles={setArticles} next={nextPage} setNext={setNextPage} showBody/>
      </Container>
    </Container>
  )
}

export default PunkInPrintPage
