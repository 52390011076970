import "./video.css";
import { useEffect, useRef, useState } from "react";
import useVideoPlayer from "../../hooks/useVideoPlayer";
import { BiPlay, BiPause } from "react-icons/bi";
import { IoIosVolumeMute, IoIosVolumeHigh } from "react-icons/io";
import { FiExternalLink } from "react-icons/fi";
import { useQuery } from "react-query";
import { getVideos } from "../../api";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import handleViewport from "react-in-viewport";

const VideoContainer = () => {
  const [video, setVideo] = useState("");

  useQuery("featured-video", () => getVideos(), {
    onSuccess: (data) => {
      if (data && data.data && data.data[0]) {
        setVideo(data.data[0]);
      }
    },
  });

  if (!video) return <></>;
  return (
    <>
      <ViewportVideo video={video} />
      <div className="video-info">
        <Link to={{ pathname: video.fieldYoutubeUrl.uri }} target="_blank">
          <h1 className="title">{video.title}</h1>
        </Link>
        {video.body && video.body.value && (
          <p
            dangerouslySetInnerHTML={{ __html: video.body.value }}
            className="video-body"
          ></p>
        )}
      </div>
    </>
  );
};

const Video = ({ video, inViewport, forwardedRef }) => {
  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
  } = useVideoPlayer(videoElement);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (videoElement.current) {
      inViewport ? videoElement.current.play() : videoElement.current.pause();
    }
  }, [inViewport, videoElement]);

  return (
    <div className="video-container" ref={forwardedRef}>
      <Link to={{ pathname: video.fieldYoutubeUrl.uri }} target="_blank">
        <video
          src={process.env.REACT_APP_STATIC_URL + video.fieldVideo.uri.url}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
          onClick={togglePlay}
          loop
          muted
        />
      </Link>
      <div className="controls">
        <div className="actions">
          <button onClick={togglePlay}>
            {!playerState.isPlaying ? (
              <BiPlay color="white" size={width < 724 ? 15 : 21} />
            ) : (
              <BiPause color="white" size={width < 724 ? 15 : 21} />
            )}
          </button>
        </div>
        <div className="progress">
          <div
            className="watched-bar"
            style={{ width: `${playerState.progress}% ` }}
          ></div>
          <input
            type="range"
            min="0"
            max="100"
            step={0.001}
            value={playerState.progress}
            onChange={(e) => handleVideoProgress(e)}
          />
        </div>
        <button className="mute-btn" onClick={toggleMute}>
          {!playerState.isMuted ? (
            <IoIosVolumeHigh color="white" size={width < 724 ? 15 : 21} />
          ) : (
            <IoIosVolumeMute color="white" size={width < 724 ? 15 : 21} />
          )}
        </button>
        <Link
          className="external-btn"
          to={{ pathname: video.fieldYoutubeUrl.uri }}
          target="_blank"
        >
          <FiExternalLink color="white" size={width < 724 ? 12 : 18} />
        </Link>
      </div>
    </div>
  );
};

const ViewportVideo = handleViewport(Video);

export default VideoContainer;
