import { Link } from "react-router-dom";
import "./home-page-featured.css";
import sanitize from "sanitize-html";
import LogoRotated from "../../assets/images/tape-rotated.png";

const HomePageFeatured = ({ article }) => {
  if (!article) return <></>;
  return (
    <div className="home-page-featured">
      <div className="background-image">
        <Link to={`/article/${article && article.fieldUrlTitle}`}>
          <div
            className="image"
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url(${
                process.env.REACT_APP_STATIC_URL + article.fieldImage.uri.url
              })`,
            }}
          >
            <img src={LogoRotated} alt="Logo" className="rotated-logo" />
            <h2>{article.title}</h2>
            <p className="body">
              {sanitize(article.body.value, { allowedTags: [] })
                .slice(0, 150)
                .replace(/[\xc2\xa0]+/g, "")}
              ...
            </p>
          </div>
        </Link>
        <div className="whitespace"></div>
      </div>
    </div>
  );
};

export default HomePageFeatured;
