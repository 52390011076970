import React, { useState } from "react";
import Container from "../../components/Container";
import ArticlesGrid from "../../components/ArticlesGrid";
import {useQuery} from "react-query";
import {getLatestArticles} from "../../api";

const NewsPage = () => {
  const [articles, setArticles] = useState([]);
  const [articlesNextPage, setArticlesNextPage] = useState();

  useQuery("articles", () => getLatestArticles(12), {
    onSuccess: (data) => {
      if (data && data.data) {
        if(articles.length <= data.data.length) {
          setArticles(data.data);
          setArticlesNextPage(data.links.next);
        }
      }
    },
  });

  return (
    <Container>
      <ArticlesGrid
        articles={articles}
        setArticles={setArticles}
        next={articlesNextPage}
        setNext={setArticlesNextPage}
        remove={0}
        title="Latest News"
      />
    </Container>
  );
};

export default NewsPage;
