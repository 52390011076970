import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "./article-teaser.css";

const ArticleTeaser = ({ article, size = "sm" }) => {
  return (
    <Link
      to={`/article/${
        article &&
        (article.url
          ? article.url
          : article.attributes && article.attributes.field_url_title)
      }`}
    >
      {size === "sm" ? (
        <div className="image-container">
          {article ? (
            <img
              loading="lazy"
              alt={article.title}
              src={`${
                article.fullImage
                  ? `${article.fullImage}`
                  : process.env.REACT_APP_STATIC_URL + article.fieldImage?.uri.url
              }`}
              className="main-image"
            />
          ) : (
            <div className="image-loading"></div>
          )}
          <div className="title">{article && <p>{article.title}</p>}</div>
        </div>
      ) : (
        size === "big" && (
          <div className="music-article-container">
            <div className="music-article-image-container">
              {article ? (
                <img
                  loading="lazy"
                  className="music-article-image"
                  src={`${
                    article.fullImage
                      ? article.fullImage
                      : process.env.REACT_APP_STATIC_URL + article.fieldImage?.uri.url
                  }`}
                  alt={article.title}
                />
              ) : (
                <div className="image-loading"></div>
              )}
            </div>
            <div className="music-article-info">
              {article ? (
                <>
                  <h2>{article.title}</h2>
                </>
              ) : (
                <>
                  <Skeleton className="category" width="80px" />
                  <br />
                  <Skeleton width="280px" height="30px" />
                  <Skeleton width="180px" height="30px" />
                </>
              )}
            </div>
          </div>
        )
      )}
    </Link>
  );
};

export default ArticleTeaser;
