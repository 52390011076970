import "./language-selector.css";
import { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import { useHistory } from "react-router-dom";

const LanguageSelector = () => {
	const { language, setLanguage } = useContext(LanguageContext);
	const history = useHistory();

	const handleClick = (lang) => {
		setLanguage(lang);
		history.go(0);
	};

	return (
		<div className="language">
			{/* <p>Language</p> */}
			<button className="lang-button" onClick={() => handleClick("en")}>
				<p className={`${language === "en" ? "selected-lang" : ""}`}>
					<strong>EN</strong> united states
				</p>
			</button>
			<button className="lang-button" onClick={() => handleClick("ka")}>
				<p className={`${language === "ka" ? "selected-lang" : ""}`}>
					<strong>KA</strong> georgia
				</p>
			</button>
		</div>
	);
};

export default LanguageSelector;
