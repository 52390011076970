import React from 'react'
import "./Container.css";

const Container = ({ size = "md", children }) => {
  return (
    <div className={`container-${size}`}>
      {children} 
    </div>
  )
}

export default Container


