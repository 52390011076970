import React, { useState } from "react";
import "./articles-grid.css";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { fetchNextPage } from "../../api";
import Like from "../../components/Like";
const sanitizeHtml = require("sanitize-html");

const ArticlesGrid = ({
  title,
  description,
  articles,
  setArticles,
  next,
  setNext,
  size = 4,
  remove = 1,
  customBorder = false,
}) => {
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  return (
    <div className="articles-grid-container">
      {title && articles && articles.length >= 1 && (
        <h3 className="articles-grid-title">{title}</h3>
      )}
      {description && articles && articles.length >= 1 && (
        <p className="articles-grid-description">{description}</p>
      )}
      {size === 4 ? (
        <div className="articles-grid grid-4">
          {articles && articles.length > 0 ? (
            articles.map(
              (article, i) =>
                i >= remove && (
                  <div
                    key={article.id || article.uuid}
                    className="articles-grid-item"
                  >
                    {(article.image || article.fieldImage) && (
                      <div
                        className={`articles-grid-image ${
                          customBorder ? "custom-border-image" : ""
                        }`}
                      >
                        <Link
                          to={`/article/${
                            article.url || article.attributes.field_url_title
                          }`}
                        >
                          {article.fieldImage &&
                          article.fieldImage.imageStyleUri ? (
                            <img
                              src={`${article.fieldImage.imageStyleUri[2].medium}`}
                              alt={article.title}
                              loading="lazy"
                              className="articles-grid-image"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src={`${article.image}`}
                              alt={article.title}
                              className="articles-grid-image"
                            />
                          )}
                        </Link>
                        <div className="article-save-container">
                          <Like article={article} />
                        </div>
                      </div>
                    )}
                    <Link
                      to={`/article/${
                        article.url || article.attributes.field_url_title
                      }`}
                    >
                      <div className="articles-grid-item-info">
                        <div className="articles-grid-item-info-header">
                          <p className="read-time">
                            {article.read_time ||
                              article.attributes.node_read_time}{" "}
                            Min Read
                          </p>
                        </div>
                        <h4 className="title">{article.title}</h4>
                        {article.body &&
                          article.body.length > 0 &&
                          article.category !== "Autovox" && (
                            <p className="body">
                              {sanitizeHtml(article.body, {
                                allowedTags: [],
                              }).slice(0, 100)}
                              ...
                            </p>
                          )}
                        {article.attributes &&
                          article.attributes.body &&
                          article.fieldCategory.name !== "Autovox" && (
                            <p className="body">
                              {sanitizeHtml(article.attributes.body.processed, {
                                allowedTags: [],
                              }).slice(0, 100)}
                              ...
                            </p>
                          )}
                      </div>
                    </Link>
                  </div>
                )
            )
          ) : (
            <>
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
              <ArticlesGridItemLoading />
            </>
          )}
        </div>
      ) : size === 2 ? (
        <div className="articles-grid grid-2">
          {articles && articles.length > 0 ? (
            articles.map(
              (article, i) =>
                i >= remove && (
                  <div
                    key={article.id || article.uuid}
                    className="articles-grid-item"
                  >
                    <div className="articles-grid-image">
                      <Link
                        to={`/article/${
                          article.url || article.attributes.field_url_title
                        }`}
                      >
                        {article.fieldImage &&
                        article.fieldImage.imageStyleUri ? (
                          <img
                            loading="lazy"
                            src={`${article.fieldImage.imageStyleUri[1].large}`}
                            alt={article.title}
                            className="articles-grid-image"
                          />
                        ) : (
                          <img
                            loading="lazy"
                            src={`${article.fullImage}`}
                            alt={article.title}
                            className="articles-grid-image"
                          />
                        )}
                      </Link>
                    </div>
                    <Link
                      to={`/article/${
                        article.url || article.attributes.field_url_title
                      }`}
                    >
                      <p className="read-time">
                        {article.read_time ||
                          article.attributes.node_read_time}{" "}
                        Min Read
                      </p>
                      <div className="articles-grid-item-info">
                        <h4 className="title">{article.title}</h4>
                      </div>
                    </Link>
                  </div>
                )
            )
          ) : (
            <>
              <ArticlesGridItemLoading size={2} />
              <ArticlesGridItemLoading size={2} />
              <ArticlesGridItemLoading size={2} />
              <ArticlesGridItemLoading size={2} />
            </>
          )}
        </div>
      ) : size === 3 ? (
        <div className="articles-grid grid-3">
          {articles && articles.length > 0 ? (
            articles.map(
              (article, i) =>
                i >= remove && (
                  <div
                    key={article.id || article.uuid}
                    className="articles-grid-item"
                  >
                    {(article.fieldImage || article.image) && (
                      <div className="articles-grid-image">
                        <Link
                          to={`/article/${
                            article.url || article.attributes.field_url_title
                          }`}
                        >
                          {article.fieldImage &&
                          article.fieldImage.imageStyleUri ? (
                            <img
                              loading="lazy"
                              src={`${article.fieldImage.imageStyleUri[1].large}`}
                              alt={article.title}
                              className="articles-grid-image"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src={`${article.fullImage}`}
                              alt={article.title}
                              className="articles-grid-image"
                            />
                          )}
                        </Link>
                      </div>
                    )}
                    <Link
                      to={`/article/${
                        article.url || article.attributes.field_url_title
                      }`}
                    >
                      <div className="articles-grid-item-info">
                        <h4 className="title">{article.title}</h4>
                      </div>
                    </Link>
                  </div>
                )
            )
          ) : (
            <>
              <ArticlesGridItemLoading size={2} />
              <ArticlesGridItemLoading size={2} />
              <ArticlesGridItemLoading size={2} />
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="loading">
        {loadingNextPage && <br />}
        <FadeLoader loading={loadingNextPage} />
      </div>
      {next && (
        <>
          <br />
          <br />
          <button
            disabled={loadingNextPage}
            className="primary-button more-articles"
            onClick={async () => {
              setLoadingNextPage(true);
              const response = await fetchNextPage(next.href);
              if (response && response.data) {
                setArticles([...articles, ...response.data]);
                setNext(response.links.next);
              }
              setLoadingNextPage(false);
            }}
          >
            See More Articles
          </button>
        </>
      )}
    </div>
  );
};

const ArticlesGridItemLoading = ({ size = 4 }) => {
  return (
    <div className="articles-grid-item">
      <div className="articles-grid-image image-loading"></div>
      <div className="articles-grid-item-info">
        <div className="articles-grid-item-info-header">
          {size === 4 && (
            <>
              <Skeleton width="80px" height="15px" />
              <Skeleton width="70px" height="15px" />
            </>
          )}
        </div>
        <br />
        <Skeleton width="180px" height="20px" />
      </div>
    </div>
  );
};

export default ArticlesGrid;
