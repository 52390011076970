import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getArticlesByUser, getUserByUsername } from "../../api";
import Container from "../../components/Container";
import ArticlesGrid from "../../components/ArticlesGrid";
import "./user.css";
import { SiGmail } from "react-icons/si";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const UserPage = ({ match }) => {
  const username = match.params.username;
  const {
    data: { data: userData },
  } = useQuery(`${username}-user`, () => getUserByUsername(username), {
    placeholderData: { data: { data: {} } },
  });
  const [user, setUser] = useState();
  const {
    data: {
      data,
      links: { next },
    },
  } = useQuery(`${username}-articles`, () => getArticlesByUser(username), {
    placeholderData: { data: {}, links: {} },
  });
  const [articles, setArticles] = useState([]);
  const [nextPage, setNextPage] = useState(false);

  useEffect(() => {
    if (data || next) {
      setArticles(data);
      setNextPage(next);
    }
  }, [data, next]);

  useEffect(() => {
    if (userData && userData.length >= 0) {
      setUser(userData[0]);
    }
  }, [userData]);

  const handleCopy = (data) => {
    window.navigator.clipboard.writeText(data);
  };

  return (
    <Container>
      <div className="user-information">
        {user ? (
          <img
            src={`${process.env.REACT_APP_STATIC_URL}${user.userPicture.uri.url}`}
            className="user-picture-big"
            alt=""
          />
        ) : (
          <div className="user-picture-big image-loading"></div>
        )}
        <div className="user-info">
          {user ? (
            <h2 className="user-name">{user.fieldFullName}</h2>
          ) : (
            <Skeleton width="300px" height="40px" />
          )}
          {user && (
            <ul className="user-social-links">
              {user.mail && (
                <li
                  className="user-social-link"
                  onClick={() => handleCopy(user.mail)}
                >
                  <SiGmail />
                </li>
              )}
              {user.attributes.field_instagram && (
                <a
                  href={user.attributes.field_instagram.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="user-social-link">
                    <FaInstagram size={20} />
                  </li>
                </a>
              )}
              {user.attributes.field_facebook && (
                <a
                  href={user.attributes.field_facebook.uri}
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="user-social-link">
                    <FaFacebookF />
                  </li>
                </a>
              )}
            </ul>
          )}
        </div>{" "}
      </div>
      <ArticlesGrid
        articles={articles}
        setArticles={setArticles}
        next={nextPage}
        setNext={setNextPage}
        remove={0}
        title="Recent Articles"
      />
    </Container>
  );
};

export default UserPage;
