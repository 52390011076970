import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import HomePage from "./pages/home";
import MusicsPage from "./pages/music";
import LiteraturePage from "./pages/literature";
import Header from "./components/Header";
import ScrollToTop from "./utils/ScrollToTop";
import useLocalStorage from "./utils/useLocalStorage";

import NewsPage from "./pages/news";
import PunkInPrintPage from "./pages/punk-in-print";
import UserPage from "./pages/user";
import ContactPage from "./pages/contact";
import PrivacyPolicyPage from "./pages/privacy-policy";

import LegalPage from "./pages/legal";

import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";
import ReactGa from "react-ga";
import AcceptSubscriptionPage from "./pages/accept-subscription";

import ThemeContext from "./context/ThemeContext";
import SavedArticlesContext from "./context/SavedArticlesContext";
import LanguageContext from "./context/LanguageContext";

const MoviesPage = loadable(() => import("./pages/movie"));
const PhotographyPage = loadable(() => import("./pages/photography"));
const ArticlePage = loadable(() => import("./pages/article"));
const DigitalArtPage = loadable(() => import("./pages/digital-art"));
const LifestylePage = loadable(() => import("./pages/lifestyle"));
const ArtcodePage = loadable(() => import("./pages/artcode"));
const AutovoxPage = loadable(() => import("./pages/autovox"));
const AboutPage = loadable(() => import("./pages/about"));

const App = () => {
  const [saved, setSaved] = useLocalStorage("saved-articles", []);
  const [darkMode, setDarkMode] = useLocalStorage("dark-mode", false);
  const [language, setLanguage] = useLocalStorage("lang", "ka");
  const location = useLocation();

  useEffect(() => {
    ReactGa.initialize("UA-207727777-1");

    ReactGa.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  }, [darkMode]);

  return (
    <>
      <ScrollToTop />
      <div className="content">
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
            <SavedArticlesContext.Provider value={{ saved, setSaved }}>
              <Switch>
                <Route path="/article/:title">
                  {({ match }) => (
                    <>
                      <Header />
                      <ArticlePage match={match} />
                    </>
                  )}
                </Route>
                <Route path="/accept-subscription/">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <AcceptSubscriptionPage />
                </Route>
                <Route path="/user/:username">
                  {({ match }) => (
                    <>
                      <Helmet>
                        <title>TAPE</title>
                      </Helmet>
                      <Header />
                      <UserPage match={match} />
                    </>
                  )}
                </Route>
                <Route path="/legal">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <LegalPage />
                </Route>
                <Route path="/privacy-policy">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <PrivacyPolicyPage />
                </Route>
                <Route path="/contact">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <ContactPage />
                </Route>
                <Route path="/punk-in-print">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Punk in Print" />
                  <PunkInPrintPage />
                </Route>
                <Route path="/news">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <NewsPage />
                </Route>
                <Route path="/autovox">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <AutovoxPage />
                </Route>
                <Route path="/lifestyle">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <LifestylePage />
                </Route>
                <Route path="/digital-art">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Digital Art" />
                  <DigitalArtPage />
                </Route>
                <Route path="/artcode">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Artcode" />
                  <ArtcodePage />
                </Route>
                <Route path="/photography">
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Photography" />
                  <PhotographyPage />
                </Route>
                <Route path="/movie" exact>
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Movie" />
                  <MoviesPage />
                </Route>
                <Route path="/literature" exact>
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Literature" />
                  <LiteraturePage />
                </Route>
                <Route path="/music" exact>
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header text="Music" />
                  <MusicsPage />
                </Route>
                <Route path="/about" exact>
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <AboutPage />
                </Route>
                <Route path="/" exact>
                  <Helmet>
                    <title>TAPE</title>
                  </Helmet>
                  <Header />
                  <HomePage />
                </Route>
              </Switch>
            </SavedArticlesContext.Provider>
          </ThemeContext.Provider>
        </LanguageContext.Provider>
      </div>
      <Footer />
    </>
  );
};

export default App;
